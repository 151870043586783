const actionTypes = {
  GET_MENU_ARR: 'GET_MENU_ARR',
  GET_SUBJECT_AUTH: 'GET_SUBJECT_AUTH',
  FLAG_HIDE_FOOT: 'FLAG_HIDE_FOOT',
  GET_CURRENT_USERINFO: 'GET_CURRENT_USERINFO',
  GET_DOCTITLE_INFO: 'GET_DOCTITLE_INFO',
  FLAG_HIDE_HEAD: 'FLAG_HIDE_HEAD',
  GET_SCHOOL_ID: 'GET_SCHOOL_ID',
  NAV_CONFIG: 'NAV_CONFIG',
  SET_MENUS: 'SET_MENUS',
  APPLY_PROVINCE: 'APPLY_PROVINCE',
  CHECK_SEPP_AUTH: 'CHECK_SEPP_AUTH',
  //快速做题权限
  TIME_ZONE_FLAG: 'TIME_ZONE_FLAG',
  LIVE_TIME_ZONE_FLAG: 'LIVE_TIME_ZONE_FLAG'
};
export default actionTypes;