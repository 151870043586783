import actionTypes from './actionTypes';
import namespace from './namespace';
export function ActionGetMenu(data) {
  return {
    type: namespace + '/' + actionTypes.GET_MENU_ARR,
    data
  };
}
export function ActionSubjectAuth(data) {
  return {
    type: namespace + '/' + actionTypes.GET_SUBJECT_AUTH,
    data
  };
}
export function ActionHideFooter(data) {
  return {
    type: namespace + '/' + actionTypes.FLAG_HIDE_FOOT,
    data
  };
}
export function ActionHideHead(data) {
  return {
    type: namespace + '/' + actionTypes.FLAG_HIDE_HEAD,
    data
  };
}
export function ActionCurrentUserInfo(data) {
  return {
    type: namespace + '/' + actionTypes.GET_CURRENT_USERINFO,
    data
  };
}
export function ActionGetDoctitleInfo(data) {
  return {
    type: namespace + '/' + actionTypes.GET_DOCTITLE_INFO,
    data
  };
}
export function ActionGetSchoolId(data) {
  return {
    type: namespace + '/' + actionTypes.GET_SCHOOL_ID,
    data
  };
}
export function ActionGetNavConfig(data) {
  return {
    type: namespace + '/' + actionTypes.NAV_CONFIG,
    data
  };
}
export function ActionSetMenus(data) {
  return {
    type: namespace + '/' + actionTypes.SET_MENUS,
    data
  };
}
export function ActionSetApplyProvince(data) {
  return {
    type: namespace + '/' + actionTypes.APPLY_PROVINCE,
    data
  };
} // 快速做题权限

export function ActionCheckSeppAuth(data) {
  return {
    type: namespace + '/' + actionTypes.CHECK_SEPP_AUTH,
    data
  };
} // 时区

export function ActionSetTimeZoneFlag(data) {
  return {
    type: namespace + '/' + actionTypes.TIME_ZONE_FLAG,
    data
  };
} //直播课时区

export function ActionSetLiveTimeZoneFlag(data) {
  return {
    type: namespace + '/' + actionTypes.LIVE_TIME_ZONE_FLAG,
    data
  };
}