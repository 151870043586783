/*
 * @Author: zhaodan 
 * @Date: 2022-09-23 14:29:15 
 * @Last Modified by: zhaodan
 * @Last Modified time: 2022-09-26 14:59:16
 */
import React, { Component } from 'react';
import { connect } from 'dva';
import { ActionHideFooter, ActionHideHead } from '@/models/layout/actions';
import { Decrypt } from '@/utils/encrypt.js';
import './style.less';

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  ActionHideFooter,
  ActionHideHead
})(class index extends Component {
  state = {
    isLocation: window.location.origin.indexOf('localhost') > -1,
    clientHeight: document.documentElement.clientHeight || document.body.clientHeight
  };

  componentDidMount() {
    this.props.ActionHideFooter(true);
    this.props.ActionHideHead(true);
    this.handleInitData();
  }

  componentWillUnmount() {
    this.props.ActionHideFooter(false);
    this.props.ActionHideHead(false);
  }

  handleInitData = () => {
    const materialPreviewUrl = sessionStorage.getItem('materialPreviewUrl');

    if (materialPreviewUrl) {
      const {
        isLocation
      } = this.state;
      const fileUrl = Decrypt(materialPreviewUrl); // console.log('fileUrl', fileUrl)

      let videoDocument = document.getElementById('filePreview');
      videoDocument.innerHTML = '';

      if (isLocation) {
        videoDocument.innerHTML = `<iframe src=${fileUrl + '#toolbar=0'} width="100%" height="100%"></iframe>`;
      } else {
        let resourceUrl = `${window.location.protocol}${fileUrl.replace("http:", "")}`;
        let escapeUrl = escape(resourceUrl);
        let iframeUrl = `${window.location.origin}/javascripts/generic/web/viewer.html?file=${escapeUrl}&disabledDownload=1`;
        videoDocument.innerHTML = `<iframe src=${iframeUrl} width="100%" height="100%"></iframe>`;
      }
    }
  };

  render() {
    const {
      clientHeight
    } = this.state;
    return <div data-inspector-line="64" data-inspector-column="6" data-inspector-relative-path="src\\containers\\Course\\Detail\\Material\\FilePreview\\index.jsx" id="filePreview" style={{
      height: clientHeight
    }}></div>;
  }

});