/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors: Guohui
 * @LastEditTime: 2023-02-23 16:36:23
 */
import React from 'react';
import RootRouter from './router';
import CommonUI from './utils-tools/commonUI.config.js';
import { message } from 'antd';
import './statics/css/change/theme.less';
import './statics/css/custom/common.css';
import './statics/css/custom/reset.css';
import './statics/css/index.less';
import { Inspector } from 'react-dev-inspector';
const InspectorWrapper = process.env.NODE_ENV === 'development' ? Inspector : React.Fragment; // React.Component = React.PureComponent;

React.CommonUI = CommonUI;
message.config({
  maxCount: 1
});
console.log("process.env.NODE_ENV ==>", process.env.NODE_ENV);
export default function App(props) {
  return <InspectorWrapper data-inspector-line="32" data-inspector-column="9" data-inspector-relative-path="src\\app.jsx" keys={['q', 'w', 'e']} disableLaunchEditor={false}>
          <RootRouter data-inspector-line="36" data-inspector-column="10" data-inspector-relative-path="src\\app.jsx" />
        </InspectorWrapper>;
}